import React, {useState} from 'react';
import "./login.css";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Api from "../../services/api";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import Cookies from "universal-cookie";
import {useDispatch ,useSelector } from "react-redux";
import {logIn} from "../../redux/actions";

const Login = () => {
    const dispatch = useDispatch()
    const cookies = new Cookies();
    const history = useHistory()
    const [ username , setUername ] = useState()
    const [ password , setPassword ] = useState()
    const handleLogin = () => {
        new Api().post("/auth/admin/login", {
            username: username,
            password: password
        }).then((res) => {
            if (typeof res !== "undefined") {
                toast.success(res.msg)
                dispatch(logIn(res.token))
                cookies.set("auth" , res.token)
                history.push("/home")
            } else {
                toast.error("نام کاربری یا کلمه عبور اشتباه است")
            }
        })
            .catch((err) => {
                toast.error(err.msg)
            })
    }
    return (
        <div className={"login"}>
            <div className={"login-box"}>
                <strong>پنل ادمین آموزشگاه زبان هلندی</strong>
                <form className={"login-form"} noValidate autoComplete="off">
                    <TextField onChange={(e) => {setUername(e.target.value)}} id="outlined-basic" label="نام کاربری" variant="outlined" size="small"/>
                    <TextField  type="password" onChange={(e) => {setPassword(e.target.value)}} id="outlined-basic" label="کلمه عیور" variant="outlined" size="small"/>
                    <Button onClick={() => {
                        handleLogin()
                    }} variant="contained" color="primary">
                        ورود
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default Login;
