import React, {useState} from 'react';
import  "./imageUploader.css"
import {ENV} from "../../services/env"
const ImageUploader = (props) => {
    const [ file , setFile ] = useState("")
    const  id = props.id
    return (
        <div className={"image-uploader"}>
            {
               <>
                   {
                       typeof props.src === "string" && (props.type === "image" || !props.type) && <img src={ENV.API.IMG + props.src}/>
                   }
                   {
                       typeof props.src === "string" && props.type === "video" &&
                       <video controls>
                           <source src={ENV.API.IMG + props.src} type="video/mp4"/>
                       </video>
                   }
               </>
            }
            <input onChange={(e) => {props.onchange(e) ; setFile(e.target.files[0] && e.target.files[0].name)}} id={id} name="image" type={"file"}/>
            <label  htmlFor={id}>
                {file !== "" ? "بارگزاری انجام شد" : props.title || "بارگزاری تصویر"}
            </label>

        </div>
    );
};

export default  ImageUploader;
