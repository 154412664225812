import { LOGIN , LOGOUT } from "../types";
import moment from 'moment-jalali';
import Cookies from "universal-cookie";

const cookies = new Cookies();

const initialState = {
    login:false
}
export default (state = initialState , action) => {
    switch (action.type) {
        case LOGOUT:
            cookies.remove('auth');
            return {
                ...state,
                login: false,
            };
        case LOGIN:
            cookies.set('auth', action.payload , { path: '/', expires: moment().add(1, "year").toDate() });
            return {
                ...state,
                login: true,
            };
        default:
            return state;
    }
};













