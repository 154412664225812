import React from 'react';
import "./Bottom.css"
const Bottom = (props) => {
    return (
        <div  onClick={(e) => {props.onClick()}} className={`bottom ${props.loading ? "loading" : ""}`}>
            {props.title}
        </div>
    );
};

export default Bottom;
