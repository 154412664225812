import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';

import { ENV } from "../services/env"

const cookies = new Cookies();


class Api {

    _headers() {
        let auth = '';
        if (cookies.get('auth')) {
            auth = cookies.get('auth');
        }
        return {
            Accept: 'application/json',
            Authorization: `${auth}`
        };
    }

    // check response after receive
    _dispatchResponse(response) {
        if (typeof response !== 'undefined') {
            if (response.status === 401) {
                cookies.remove('auth')
            } else if (response.status === 404) {
                toast.error('خطای سرویس گیرنده: آدرس وجود ندارد و یا سرور دان است.');
            } else {
                toast.error(response.statusText);
            }
        } else {
            console.log(response)
        }
    }



    get(url, object= {}) {
        return axios.get( ENV.API[window.location.host] +`${url}`, {
            headers: this._headers(),
            params:  object
        }).then( (response) => {
            return response.data;
        }).catch((error) => {
            return this._dispatchResponse(error.response)
        })
    }

    post(url, object= {}) {
        return axios.post( ENV.API[window.location.host] + `${url}`, object, {
            headers: this._headers(),
        }).then( (response) => {
            return response.data;
        }).catch((error) => {
            return this._dispatchResponse(error.response)
        })
    }

    put(url, object={}) {
        return axios.put( ENV.API[window.location.host]+ `${url}`, object , {
            headers: this._headers(),
        }).then( (response) => {
            return response.data;
        }).catch((error) => {
            return this._dispatchResponse(error.response)
        })
    }

    delete(url) {
        return axios.delete( ENV.API[window.location.host]+ `${url}`, {
            headers: this._headers(),
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return this._dispatchResponse(error.response)
        })
    }


    upload(url, object= {}) {
        return axios.post( ENV.API[window.location.host]+ `${url}`, object, {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ` + cookies.get('auth')
            }
        }).then( (response) => {
            return response.data;
        }).catch((error) => {
            return this._dispatchResponse(error.response)
        })
    }



}

export default Api;
