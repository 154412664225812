import React, {useState} from 'react';
import "./input.css"

const Input = (props) => {

    return (
        <div className={"input"}>
            <label>
                {props.title + " : "}
            </label>
            <input value={props.value} onChange={(e) => { props.onChange(e.target.value)}} placeholder={"لطفا متن را وارد کنید"}/>
        </div>
    );
};

export default Input;
