import React, {useState, useEffect, useRef} from 'react';
import "./home.css"
import Layout from "../../component/layout/layout";
import Input from "../../component/input/input";
import Textarea from "../../component/textArea/textArea";
import Bottom from "../../component/bottom/Bottom";
import {toast} from 'react-toastify';
import Api from "../../services/api";
import deleteIcon from "../../res/img/delete.svg";
import editIcon from "../../res/img/edit.png";
import {ENV} from "../../services/env"
import ImageUploader from "../../component/imageUploader/imageUploader"
import {useDispatch} from "react-redux";
import {logOut} from "../../redux/actions";
import {useHistory, useLocation} from "react-router-dom";
import SunEditor, {buttonList} from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import moment from "moment-jalaali";
import Cookies from "universal-cookie";
import Pagination from "react-js-pagination";
import queryString from "query-string";

const Home = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const cookies = new Cookies();
    const location = useLocation();

    const query = queryString.parse(location && location.search);

    const [page, setPage] = useState(query.page || null)

    const [tabs, setTabs] = useState(parseInt(localStorage.getItem("tab")) || 1)
    const [title, setTitle] = useState("")
    const [selectedImage, setSelectedImage] = useState("")
    const [selectedMainImage, setSelectedMainImage] = useState("")
    const [description, setDescription] = useState(1)
    const [introductionList, setIntroductionList] = useState([])
    const [blogList, setBlogList] = useState([])
    const [experienceList, setExperienceList] = useState([])
    const [menuExperienceData, setMenuExperienceData] = useState([])
    const [postList, setPostList] = useState([])
    const [bannerList, setBannerList] = useState([])
    const [galleryList, setGalleryList] = useState([])
    const [continueModal, setContinueModal] = useState(false)
    const [postId, setPostId] = useState(0)
    const [metaTitle, setMetaTitle] = useState("")
    const [paginationData, setPaginationData] = useState([])
    const [introduce, setIntroduce] = useState("")
    const [instagram, setInstagram] = useState("")
    const [facebook, setFacebook] = useState("")
    const [telegram, setTelegram] = useState("")
    const [youtube, setYoutube] = useState("")
    const [whatsApp, setWhatsApp] = useState("")
    const [setting, setSetting] = useState("")
    const [email, setEmail] = useState([])
    const [loading, setLoading] = useState(true)
    const [menuData, setMenuData] = useState([])
    const [menuGalleryData, setMenuGalleryData] = useState([])
    const [newCategory, setNewCategory] = useState("")
    const [selectCategory, setSelectCategory] = useState(null)
    const [expanded, setExpanded] = useState([])
    const [showCategoryModal, setShowCategoryModal] = useState(false)
    const [selectedCtegoryId, setSelectedCtegoryId] = useState(null)
    const [toggle, setToggle] = useState(false)
    const [toggleNumber, setToggleNumber] = useState(false)
    const [mainText, setMainText] = useState([])
    const [mainTextList, setMainTextList] = useState([])
    const [mainNumber, setMainNumber] = useState([])
    const [mainNumberList, setMainNumberList] = useState([])
    const [emails, setEmails] = useState([])
    const [allEmails, setAllEmails] = useState(false)
    const [videoList, setVideoList] = useState([])

    const editor = useRef();

    useEffect(() => {
        let auth = cookies.get('auth')
        if (auth === undefined || auth === null || auth === "") {
            handleLogOut()
        }
    }, [])
    useEffect(() => {
        setPage(query.page)
    }, [query.page])

    useEffect(() => {
        setPage(1)

         history.push(`/home`)
        setSelectedCtegoryId(null)
        setShowCategoryModal(false)
    }, [tabs])

    const handlePageChange = async (active) => {
        setPage(active)
        window.scrollTo({top: 0, behavior: "smooth"})
        await history.push(`/home?page=${ active || 1 }`)
        switch(tabs) {
            case 14 :
                experience(active)
            case 3 :
                gallery(active)
        }
    }
    const introduction = () => {
        new Api().get("/introduction")
            .then((res) => {
                setIntroductionList(res)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const validatorSelectCategory = () => {
        if (newCategory === "") {
            toast.error("لطفا دسته بندی را انتخاب کنید")
            return false
        }
        return true
    }
    const validatorRemoveCategory = () => {
        if (selectCategory === null) {
            toast.error("لطفا دسته بندی را انتخاب کنید")
            return false
        }
        return true
    }
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;

    };
    const blog = () => {
        new Api().get("/blog")
            .then((res) => {
                setBlogList(res)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const experience = (active = 1) => {
        new Api().get(`/experience/?page=${ active }`)
            .then((res) => {
                setExperienceList(res)
                setPaginationData(res)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const post = () => {
        new Api().get("/post")
            .then((res) => {
                setPostList(res)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const banner = () => {
        new Api().get("/banner")
            .then((res) => {
                setBannerList(res)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const gallery = (active = 1) => {
        new Api().get(`/gallery/?page=${ active }`)
            .then((res) => {
                setGalleryList(res)
                setPaginationData(res)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const video = () => {
        new Api().get("/gallery/video")
            .then((res) => {
                setVideoList(res)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const handleAddExpand = async (value, child) => {
        if (expanded.includes(value)) {
            const newArr = [...expanded]
            const index = expanded.indexOf(value);
            await newArr.splice(index, 1);
            await setExpanded(newArr)
        } else {
            const newArr = [...expanded]
            await newArr.push(value);
            await setExpanded(newArr)
        }
    }
    const sendEmail = () => {
        new Api().post("/mail" , {description : description , emails_list : emails })
            .then((res) => {
                toast.success(res.msg)
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const handleExel = () => {
        new Api().post("/mail/exel" , { list : email })
            .then((res) => {
                toast.success(res.msg)
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const renderTree = (props, keys = "empty") => {
        console.log(props)
        let filterKey = props && props?.filter((item) => {
            return (item.type || "empty" ) === keys
        })
        // nodes mapping
        const treeNodes = filterKey && filterKey.map((item, key) => {
            // value id node
            const {_id} = item;
            // label title node
            const {title} = item;
            // slug
            const {slug} = item;

            // check has child
            const hasChild = item && item.child && item.child.length > 0;

            // check has child is true fetch children

            const children = hasChild ? renderTree(item.child, keys) : '';
            return (
                <>
                    <div
                        className={"tree_view"}>
                        {hasChild === true &&
                        <img onClick={(e) => e.target === e.currentTarget && handleAddExpand(_id, item.child)}
                             src={!expanded.includes(_id) ? "/plus_icon.svg" : "/minus_icon.svg"}/>
                        }
                        <li key={key} className={"tree_box"}>
                          <span
                              className={
                                  hasChild === true
                                      ? 'tree-parent has-child'
                                      : 'tree-parent  has-no-child'
                              }
                          >
                          </span>
                            <div className={"tree_box_title"}>
                                <input className={"radio-category"} name="category" type={"radio"} value={item._id}
                                       onChange={(e) => {
                                           setSelectCategory(e.target.value);
                                           setSelectedCtegoryId(e.target.value)
                                       }}/>
                                <div> {title}</div>
                            </div>
                            {hasChild === true && (
                                <ul
                                    style={{
                                        display: expanded.includes(_id)
                                            ? 'flex'
                                            : 'none'
                                    }}
                                    className={"tree_children"}
                                >
                                    <div>
                                        {children}
                                    </div>

                                </ul>
                            )}
                        </li>
                    </div>
                </>
            );
        });
        return treeNodes;
    }

    const getEmail = () => {
        new Api().get("/newsLetter")
            .then((res) => {
                setEmail(res)
            })
    }
    const removeItem = (index , state , setState) => {
        let arr = [...state]
        arr.splice(index , 1)
        setState(arr)
    }
    const getSetting = () => {
        setLoading(true)
        new Api().get("/setting")
            .then((res) => {
                setLoading(false)
                setSetting(res)
                let link = JSON.parse(res.links)
                setMetaTitle(res.title)
                setFacebook(link.facebook)
                setTelegram(link.telegram)
                setYoutube(link.youtube)
                setWhatsApp(link.whatsapp)
                setIntroduce(res.introduce)
                setInstagram(link.instagram)
                setSelectedMainImage(res.banner)
                setMainTextList(JSON.parse(res.main_information))
                setMainNumberList(JSON.parse(res.number_list))
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const sendSettingData = (e) => {

        const formData = new FormData()
        const images = selectedMainImage
        let links = JSON.stringify({
            "instagram": instagram,
            "telegram": telegram,
            "youtube": youtube,
            "facebook": facebook,
            "gmail": "",
            "whatsapp": whatsApp,
        })
        formData.append("title", metaTitle)
        formData.append("introduce", introduce)
        formData.append("image", images)
        formData.append("links", links)
        formData.append("main_information", JSON.stringify(mainTextList))
        formData.append("number_list", JSON.stringify(mainNumberList))

        setLoading(true)
        new Api().put(`/setting${setting && setting._id ? "/" + setting._id : ''}`, formData )
            .then((res) => {
                setLoading(false)
                if (typeof res !== "undefined") {
                    getSetting()
                    toast.success(res.msg)
                    setSetting(res.result)
                }
            })
            .catch((err) => {
                setLoading(false)
                toast.error(err)
            })
    }
    const handleMenu = (key, id = null) => {
        if (!validatorSelectCategory()) return false
        new Api().post("/menu", {id: selectCategory, title: newCategory, type: key})
            .then((res) => {
                setNewCategory('')
                setMenuData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const handleRemoveMenu = (key, id = null) => {
        if (!validatorRemoveCategory()) return false
        new Api().delete(`/menu/${selectCategory}`)
            .then((res) => {
                setNewCategory('')
                menu()
                setMenuData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const handleExperienceMenu = (key, id = null) => {
        if (!validatorSelectCategory()) return false
        new Api().post("/menu/experience", {id: selectCategory, title: newCategory})
            .then((res) => {
                setNewCategory('')
                setMenuExperienceData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const handleRemoveExperience = (key, id = null) => {
        if (!validatorRemoveCategory()) return false
        new Api().delete(`/menu/experience/${selectCategory}`)
            .then((res) => {
                setNewCategory('')
                setMenuExperienceData(res.result)
                menuExperience()

            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const handleGalleryMenu = (key, id = null) => {
        if (!validatorSelectCategory()) return false
        new Api().post("/menu/gallery", {id: selectCategory, title: newCategory, type: key})
            .then((res) => {
                setNewCategory('')
                setMenuGalleryData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const handleRemoveGalleryMenu = (key, id = null) => {
        if (!validatorRemoveCategory()) return false
        new Api().delete(`/menu/gallery/${selectCategory}`)
            .then((res) => {
                setNewCategory('')
                setMenuGalleryData(res.result)
                menuGallery()
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const handleSelectMail = (newEmail , key) => {
        if(key === "all") {
            setAllEmails(newEmail) ;
            if(!newEmail) {
                setEmails([])

            } else {
                let arr = []
                email && email.map((item) => {
                    return (
                        arr.push(item.email)
                    )
                })
                setEmails(arr)
            }

        } else {
            let arr = [...emails]
            let index = arr.indexOf(newEmail)

            if(index !== -1) {
                arr.splice(index , 1)
                setEmails(arr)

            } else {
                setEmails([...emails , newEmail])
            }
        }

    }
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
        getSetting()
        introduction()
        blog()
        experience()
        menuExperience()
        post()
        gallery()
        getEmail()
        banner()
        menu()
        menuGallery()
        video()

    }, []);

    const menu = () => {
        new Api().get("/menu")
            .then((res) => {
                setMenuData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const menuGallery = () => {
        new Api().get("/menu/gallery")
            .then((res) => {
                setMenuGalleryData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const menuExperience = () => {
        new Api().get("/menu/experience")
            .then((res) => {
                setMenuExperienceData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const sendData = (e) => {
        const images = document.querySelector("#input-file").files[0]
        const formData = new FormData()
        formData.append("title", title)
        formData.append("description", description)
        formData.append("image", images)
        new Api().post("/introduction", formData)
            .then((res) => {
                introduction()
                toast.success(res.msg)

            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const sendBlogData = (e) => {
        const formData = new FormData()
        const images = selectedImage
        formData.append("title", title)
        formData.append("description", description)
        formData.append("image", images)
        formData.append("category_id", selectedCtegoryId)
        new Api().post("/blog", formData)
            .then((res) => {
                blog()
                toast.success(res.msg)
            })
            .catch((res) => {
                toast.error("خطایی رخ داده است")
            })
    }
    const sendExperienceData = (e) => {
        const formData = new FormData()
        const images = selectedImage
        formData.append("title", title)
        formData.append("description", description)
        formData.append("image", images)
        formData.append("category_id", selectedCtegoryId)
        new Api().post("/experience", formData)
            .then((res) => {
                experience()
                toast.success(res.msg)
            })
            .catch((res) => {
                toast.error("خطایی رخ داده است")
            })
    }
    const sendPostData = (e) => {
        const formData = new FormData()
        const images = selectedImage
        formData.append("title", title)
        formData.append("description", description)
        formData.append("image", images)
        new Api().post("/post", formData)
            .then((res) => {
                post()
                toast.success(res.msg)
            })
            .catch((res) => {
                toast.error("خطایی رخ داده است")
            })
    }
    const sendBannerData = (e) => {
        const formData = new FormData()
        const images = selectedImage
        formData.append("title", title)
        formData.append("image", images)
        new Api().post("/banner", formData)
            .then((res) => {
                banner()
                toast.success(res.msg)
            })
            .catch((res) => {
                toast.error("خطایی رخ داده است")
            })
    }

    const sendGalleryData = (key) => {
        let images
        if (key === "video") {
            images = document.querySelector("#input-file-gallery-video").files[0]

        } else {
            images = document.querySelector("#input-file-gallery-image").files[0]

        }
        const formData = new FormData()
        formData.append("title", title)
        formData.append("description", description)
        formData.append("image", images)
        formData.append("type", key)
        formData.append("category_id", selectedCtegoryId)

        new Api().post("/gallery", formData)
            .then((res) => {
                gallery()
                toast.success("عملیات با موفقیت انجام شد")
            })
            .catch((err) => {
                toast.error(err)
            })
    }


    const deleteData = (route, id) => {
        new Api().delete(`/${route}/${id}`)
            .then((res) => {
                switch (route) {
                    case "introduction" :
                        introduction()
                        break
                    case "blog" :
                        blog()
                        break
                    case "experience" :
                        experience()
                        break
                    case "gallery" :
                        gallery()
                        break
                    case "banner" :
                        banner()
                        break
                    case "post" :
                        post()
                        break
                }
                setContinueModal(false)
                toast.success(res.msg)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const tab = [
        {title: " تولید محتوا ( درباره هلند ) ", id: 1},
        {title: "تجربه زبان آموزان ", id: 14},
        {title: "پست های تکی", id: 9},
        {title: "فیلم های آموزشی", id: 10},
        {title: "معرفی اساتید و آموزشگاه", id: 2},
        {title: "کارنامه ها", id: 3},
        {title: "تنظیمات سایت", id: 4},
        {title: "لیست افراد عضو شده ", id: 5},
        {title: "دسته بندی کارنامه ها", id: 11},
        {title: "دسته بندی فیلم های آموزشی", id: 12},
        {title: "دسته بندی تولید محتوا ( درباره هلند )", id: 7},
        {title: "دسته بندی تجربه زبان آموزان", id: 13},
        {title: "بارگزاری بنر", id: 8},
    ]
    const handleLogOut = () => {
        dispatch(logOut())
        history.push("/")
    }
    const handleTree = (tabs) => {
        switch (tabs) {
            case 1 :
                return renderTree(menuData, "menu")
            case 3 :
                return renderTree(menuGalleryData, "image")
            case 10 :
                return renderTree(menuGalleryData, "video")
            case 14 :
                return renderTree(menuExperienceData)
        }
    }
    const handleCategory = () => {
        return (
            <div className={`${showCategoryModal ? "show-category-modal" : "hide-category-modal"} modal-category`}>
                {handleTree(tabs)}
                <div className={"category-btn-section"}>
                    <div onClick={() => {
                        setShowCategoryModal(!showCategoryModal)
                    }} className={"category-btn"}>
                        بستن
                    </div>
                </div>
            </div>
        )
    }
    const selectTab = () => {
        switch (tabs) {
            case 1:
                return (
                    <>
                        <div onClick={() => {
                            setShowCategoryModal(!showCategoryModal)
                        }} className={"category-btn"}>
                            انتخاب دسته بندی
                        </div>
                        <div onClick={(e) => {
                            e.target === e.currentTarget && setContinueModal(false)
                        }} className={`modal-continue ${continueModal ? "show" : "hidden"}`}>
                            <div className={`modal-continue-box`}>
                                <strong>آیا از ادامه درخواست مطمین هستید ؟</strong>
                                <div>
                                    <div onClick={() => {
                                        deleteData('blog', postId)
                                    }} className={"btn success"}>
                                        بله
                                    </div>
                                    <div onClick={(e) => {
                                        setContinueModal(false)
                                    }} className={"btn danger"}>
                                        خیر
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ImageUploader onchange={(e) => {
                            setSelectedImage(e.target.files[0])
                        }} id={"input-file-blog"}/>
                        <Input onChange={(e) => {
                            setTitle(e)
                        }} title={"عنوان "}/>
                        <SunEditor
                            setOptions={{
                                height: 200,
                                buttonList: buttonList.complex
                            }}
                            height="400px"
                            getSunEditorInstance={getSunEditorInstance}
                            onChange={(e) => {
                                setDescription(e)
                            }}/>
                        <Bottom onClick={() => {
                            sendBlogData()
                        }} title={"ثبت"}/>
                        <div className={"list"}>
                            {blogList && blogList.list && blogList.list.map((item) => {
                                return (
                                    <div className={"list-items"}>
                                        <div className={"list-items-img"}><img src={ENV.API.IMG + item.image}/></div>
                                        <div className={"list-items-title"}>{item.title}</div>
                                        <div
                                            className={"list-items-title"}>{moment(item.create_at).format('jYYYY/jM/jD')}</div>
                                        <div className={"list-items-option"}>
                                            <img onClick={() => {
                                                setContinueModal(true);
                                                setPostId(item._id)
                                            }} src={deleteIcon}/>
                                            <img onClick={() => history.push(`/home/edit/blog/${item._id}`, {item})}
                                                 src={editIcon}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            case 2:
                return (
                    <>
                        <div onClick={(e) => {
                            e.target === e.currentTarget && setContinueModal(false)
                        }} className={`modal-continue ${continueModal ? "show" : "hidden"}`}>
                            <div className={`modal-continue-box`}>
                                <strong>آیا از ادامه درخواست مطمین هستید ؟</strong>
                                <div>
                                    <div onClick={() => {
                                        deleteData("introduction", postId)
                                    }} className={"btn success"}>
                                        بله
                                    </div>
                                    <div onClick={(e) => {
                                        setContinueModal(false)
                                    }} className={"btn danger"}>
                                        خیر
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ImageUploader onchange={(e) => {
                            setSelectedImage(e.target.files[0])
                        }} id={"input-file"}/>
                        <Input onChange={(e) => {
                            setTitle(e)
                        }} title={"عنوان "}/>
                        <SunEditor
                            setOptions={{
                                height: 200,
                                buttonList: buttonList.complex
                            }}
                            height="400px"
                            getSunEditorInstance={getSunEditorInstance}
                            onChange={(e) => {
                                setDescription(e)
                            }}/>
                        <Bottom onClick={() => {
                            sendData()
                        }} title={"ثبت"}/>
                        <div className={"list"}>
                            {introductionList && introductionList.list && introductionList.list.map((item) => {
                                return (
                                    <div className={"list-items"}>
                                        <div className={"list-items-img"}><img src={ENV.API.IMG + item.image}/></div>
                                        <div className={"list-items-title"}>{item.title}</div>
                                        <div className={"list-items-option"}>
                                            <img onClick={() => {
                                                setContinueModal(true);
                                                setPostId(item._id)
                                            }} src={deleteIcon}/>
                                            <img
                                                onClick={() => history.push(`/home/edit/introduction/${item._id}`, {item})}
                                                src={editIcon}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            case 3:
                return (
                    <>
                        <div onClick={(e) => {
                            e.target === e.currentTarget && setContinueModal(false)
                        }} className={`modal-continue ${continueModal ? "show" : "hidden"}`}>
                            <div className={`modal-continue-box`}>
                                <strong>آیا از ادامه درخواست مطمین هستید ؟</strong>
                                <div>
                                    <div onClick={() => {
                                        deleteData("gallery", postId)
                                    }} className={"btn success"}>
                                        بله
                                    </div>
                                    <div onClick={(e) => {
                                        setContinueModal(false)
                                    }} className={"btn danger"}>
                                        خیر
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div onClick={() => {
                            setShowCategoryModal(!showCategoryModal)
                        }} className={"category-btn"}>
                            انتخاب دسته بندی
                        </div>
                        <ImageUploader onchange={(e) => {
                            setSelectedImage(e.target.files[0])
                        }} id={"input-file-gallery-image"}/>
                        <Input onChange={(e) => {
                            setTitle(e)
                        }} title={"عنوان "}/>
                        <SunEditor
                            setOptions={{
                                height: 200,
                                buttonList: buttonList.complex
                            }}
                            height="400px"
                            getSunEditorInstance={getSunEditorInstance}
                            onChange={(e) => {
                                setDescription(e)
                            }}/>
                        <Bottom onClick={() => {
                            sendGalleryData("image")
                        }} title={"ثبت"}/>


                        <div className={"list"}>
                            {galleryList.list && galleryList.list.map((item) => {

                                return (
                                    item.type === "image" &&
                                    <div className={"list-items"}>
                                        <div className={"list-items-img"}>
                                            <img src={ENV.API.IMG + item.file}/>
                                        </div>
                                        <strong className={"list-items-title"}>{item.title}</strong>
                                        <div className={"list-items-option"}>
                                            <img onClick={() => {
                                                setContinueModal(true);
                                                setPostId(item._id)
                                            }} src={deleteIcon}/>
                                            <img onClick={() => history.push(`/home/edit/gallery/${item._id}`, {item})}
                                                 src={editIcon}/>

                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            case 4:
                return (
                    <div>
                        <Input value={metaTitle} onChange={(e) => {
                            setMetaTitle(e)
                        }} title={"عنوان سایت "}/>
                        <Input value={introduce} onChange={(e) => {
                            setIntroduce(e)
                        }} title={"خلاصه معرفی سایت  "}/>
                        <Input value={instagram} onChange={(e) => {
                            setInstagram(e)
                        }} title={"لینک اینستاگرام "}/>

                        <Input value={facebook} onChange={(e) => {
                            setFacebook(e)
                        }} title={"لینک فیسبوک "}/>
                        <Input value={telegram} onChange={(e) => {
                            setTelegram(e)
                        }} title={"لینک تلگرام "}/>
                        <Input value={youtube} onChange={(e) => {
                            setYoutube(e)
                        }} title={"لینک یوتیوب "}/>
                        <Input value={whatsApp} onChange={(e) => {
                            setWhatsApp(e)
                        }} title={"لینک واتساپ "}/>

                        <div className={`drop-down-tab ${toggle ? " show-drop" : "hide-drop"}`}>
                            <div  onClick={(e) => e.target === e.currentTarget &&  setToggle(!toggle)} className={"drop-down-tab-title"}>
                                بنر اصلی سایت
                            </div>
                            <ImageUploader type={"image"} src={selectedMainImage} onchange={(e) => {
                                setSelectedMainImage(e.target.files[0])
                            }} id={"input-file-gallery-image"}/>
                            <div className={"main-text-section"}>
                                <div className={"main-text-section-title"}>
                                    <input onChange={(e) => {setMainText(e.target.value)}} value={mainText}/>
                                    <div onClick={() => {setMainTextList([...mainTextList , mainText]) ; setMainText("")}} className={"add-main-text"}>
                                        افزودن
                                    </div>
                                </div>

                                {
                                    mainTextList && mainTextList.map((item , index) => {
                                        return (
                                            <div className={"text-list"}>
                                                <span>
                                                    {item}
                                                </span>
                                                <span onClick={() => {removeItem(index , mainTextList , setMainTextList)}}>
                                                    حذف
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={`drop-down-tab ${toggleNumber ? " show-drop" : "hide-drop"}`}>
                            <div  onClick={(e) => e.target === e.currentTarget &&  setToggleNumber(!toggleNumber)} className={"drop-down-tab-title"}>
                                لیست شماره تلفن
                            </div>
                            <div className={"main-text-section"}>
                                <div className={"main-text-section-title"}>
                                    <input onChange={(e) => {setMainNumber(e.target.value)}} value={mainNumber}/>
                                    <div onClick={() => {setMainNumberList([...mainNumberList , mainNumber]) ; setMainNumber("")}} className={"add-main-text"}>
                                        افزودن
                                    </div>
                                </div>
                                {
                                    mainNumberList && mainNumberList.map((item , index) => {
                                        return (
                                            <div className={"text-list"}>
                                                <span>
                                                    {item}
                                                </span>
                                                <span onClick={() => {removeItem(index , mainNumberList , setMainNumberList)}}>
                                                    حذف
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <Bottom loading={loading} onClick={() => {
                            sendSettingData()
                        }} title={"ثبت"}/>
                    </div>
                )
            case 5:
                return (
                    <div className={'email-list'}>
                        {
                            email && email.length === 0 ? "در حال حاضر ایمیلی ثبت نشده است ."
                                :

                                <div className={"email-list-item"}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>انتخاب</th>
                                            <th>ردیف</th>
                                            <th>نام</th>
                                            <th>نام خانوادگی</th>
                                            <th>شماره موبایل</th>
                                            <th>ایمیل</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            email && email.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <input checked={emails && emails.includes(item.email)} name={"email"} onChange={() => {handleSelectMail(item.email , "single")}} type={"checkbox"}/>
                                                        </td>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.family}</td>
                                                        <td>{item.number}</td>
                                                        <td>{item.email}</td>

                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                            <td>
                                                <div>
                                                    <span > همه</span>
                                                    <input checked={allEmails} name={"email"} onChange={(e) => { handleSelectMail(e.target.checked , "all")}} type={"checkbox"}/>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <SunEditor
                                        setOptions={{
                                            height: 200,
                                            buttonList: buttonList.complex
                                        }}
                                        height="400px"
                                        getSunEditorInstance={getSunEditorInstance}
                                        onChange={(e) => {
                                            setDescription(e)
                                        }}/>
                                    <Bottom onClick={() => {
                                        sendEmail()
                                    }} title={"ارسال ایمیل"}/>
                                    <Bottom onClick={() => {
                                        handleExel()
                                    }} title={"دریافت فایل اکسل"}/>
                                </div>
                        }
                    </div>
                )
            case 11:
                return (
                    <>
                        <div className={"create-new-menu"}>
                            <input value={newCategory} onChange={(e) => setNewCategory(e.target.value)}
                                   placeholder={"دسته بندی جدید"}/>
                            <div onClick={() => {
                                handleGalleryMenu("image")
                            }} className={"create-new-menu-add"}>افزودن
                            </div>
                            <div onClick={() => {
                                handleRemoveGalleryMenu("image")
                            }} className={"create-new-menu-remove"}>حذف
                            </div>

                        </div>
                        <div className={"create-new-menu-added"}>
                            {renderTree(menuGalleryData, "image")}
                        </div>
                    </>
                )
            case 12:
                return (
                    <>
                        <div className={"create-new-menu"}>
                            <input value={newCategory} onChange={(e) => setNewCategory(e.target.value)}
                                   placeholder={"دسته بندی جدید"}/>
                            <div onClick={() => {
                                handleGalleryMenu("video")
                            }} className={"create-new-menu-add"}>افزودن
                            </div>
                            <div onClick={() => {
                                handleRemoveGalleryMenu("video")
                            }} className={"create-new-menu-remove"}>حذف
                            </div>

                        </div>
                        <div className={"create-new-menu-added"}>
                            {renderTree(menuGalleryData, "video")}
                        </div>
                    </>
                )
            case 13 :
                return (
                    <>
                        <div className={"create-new-menu"}>
                            <input value={newCategory} onChange={(e) => setNewCategory(e.target.value)}
                                   placeholder={"دسته بندی جدید"}/>
                            <div onClick={() => {
                                handleExperienceMenu()
                            }} className={"create-new-menu-add"}>افزودن
                            </div>
                            <div onClick={() => {
                                handleRemoveExperience()
                            }} className={"create-new-menu-remove"}>حذف
                            </div>

                        </div>
                        <div className={"create-new-menu-added"}>
                            {renderTree(menuExperienceData)}
                        </div>
                    </>
                )
            case 14 :
                return (
                    <>
                        <div onClick={() => {
                            setShowCategoryModal(!showCategoryModal)
                        }} className={"category-btn"}>
                            انتخاب دسته بندی
                        </div>
                        <div onClick={(e) => {
                            e.target === e.currentTarget && setContinueModal(false)
                        }} className={`modal-continue ${continueModal ? "show" : "hidden"}`}>
                            <div className={`modal-continue-box`}>
                                <strong>آیا از ادامه درخواست مطمین هستید ؟</strong>
                                <div>
                                    <div onClick={() => {
                                        deleteData('experience', postId)
                                    }} className={"btn success"}>
                                        بله
                                    </div>
                                    <div onClick={(e) => {
                                        setContinueModal(false)
                                    }} className={"btn danger"}>
                                        خیر
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ImageUploader onchange={(e) => {
                            setSelectedImage(e.target.files[0])
                        }} id={"input-file-blog"}/>
                        <Input onChange={(e) => {
                            setTitle(e)
                        }} title={"عنوان "}/>
                        <SunEditor
                            setOptions={{
                                height: 200,
                                buttonList: buttonList.complex
                            }}
                            height="400px"
                            getSunEditorInstance={getSunEditorInstance}
                            onChange={(e) => {
                                setDescription(e)
                            }}/>
                        <Bottom onClick={() => {
                            sendExperienceData()
                        }} title={"ثبت"}/>
                        <div className={"list"}>
                            {experienceList && experienceList.list && experienceList.list.map((item) => {
                                return (
                                    <div className={"list-items"}>
                                        <div className={"list-items-img"}><img src={ENV.API.IMG + item.image}/></div>
                                        <div className={"list-items-title"}>{item.title}</div>
                                        <div
                                            className={"list-items-title"}>{moment(item.create_at).format('jYYYY/jM/jD')}</div>
                                        <div className={"list-items-option"}>
                                            <img onClick={() => {
                                                setContinueModal(true);
                                                setPostId(item._id)
                                            }} src={deleteIcon}/>
                                            <img onClick={() => history.push(`/home/edit/experience/${item._id}`, {item})}
                                                 src={editIcon}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            case 7:
                return (
                    <>
                        <div className={"create-new-menu"}>
                            <input value={newCategory} onChange={(e) => setNewCategory(e.target.value)}
                                   placeholder={"دسته بندی جدید"}/>
                            <div onClick={() => {
                                handleMenu("menu")
                            }} className={"create-new-menu-add"}>افزودن
                            </div>
                            <div onClick={() => {
                                handleRemoveMenu("menu")
                            }} className={"create-new-menu-remove"}>حذف
                            </div>

                        </div>
                        <div className={"create-new-menu-added"}>
                            {renderTree(menuData && menuData, "menu")}
                        </div>
                    </>
                )
            case 8 :
                return (
                    <>
                        <div onClick={(e) => {
                            e.target === e.currentTarget && setContinueModal(false)
                        }} className={`modal-continue ${continueModal ? "show" : "hidden"}`}>
                            <div className={`modal-continue-box`}>
                                <strong>آیا از ادامه درخواست مطمین هستید ؟</strong>
                                <div>
                                    <div onClick={() => {
                                        deleteData('banner', postId)
                                    }} className={"btn success"}>
                                        بله
                                    </div>
                                    <div onClick={(e) => {
                                        setContinueModal(false)
                                    }} className={"btn danger"}>
                                        خیر
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ImageUploader onchange={(e) => {
                            setSelectedImage(e.target.files[0])
                        }} id={"input-file-banner"}/>
                        <Input onChange={(e) => {
                            setTitle(e)
                        }} title={"عنوان "}/>
                        <Bottom onClick={() => {
                            sendBannerData()
                        }} title={"ثبت"}/>
                        <div className={"list"}>
                            {bannerList && bannerList.list && bannerList.list.map((item) => {
                                return (
                                    <div className={"list-items"}>
                                        <div className={"list-items-img"}><img src={ENV.API.IMG + item.image}/></div>
                                        <div className={"list-items-title"}>{item.title}</div>
                                        <div
                                            className={"list-items-title"}>{moment(item.create_at).format('jYYYY/jM/jD')}</div>
                                        <div className={"list-items-option"}>
                                            <img onClick={() => {
                                                setContinueModal(true);
                                                setPostId(item._id)
                                            }} src={deleteIcon}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            case 9:
                return (
                    <>
                        <div onClick={(e) => {
                            e.target === e.currentTarget && setContinueModal(false)
                        }} className={`modal-continue ${continueModal ? "show" : "hidden"}`}>
                            <div className={`modal-continue-box`}>
                                <strong>آیا از ادامه درخواست مطمین هستید ؟</strong>
                                <div>
                                    <div onClick={() => {
                                        deleteData('post', postId)
                                    }} className={"btn success"}>
                                        بله
                                    </div>
                                    <div onClick={(e) => {
                                        setContinueModal(false)
                                    }} className={"btn danger"}>
                                        خیر
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ImageUploader onchange={(e) => {
                            setSelectedImage(e.target.files[0])
                        }} id={"input-file-blog"}/>
                        <Input onChange={(e) => {
                            setTitle(e)
                        }} title={"عنوان "}/>
                        <SunEditor
                            setOptions={{
                                height: 200,
                                buttonList: buttonList.complex
                            }}
                            height="400px"
                            getSunEditorInstance={getSunEditorInstance}
                            onChange={(e) => {
                                setDescription(e)
                            }}/>
                        <Bottom onClick={() => {
                            sendPostData()
                        }} title={"ثبت"}/>
                        <div className={"list"}>
                            {postList && postList && postList.map((item) => {
                                return (
                                    <div className={"list-items"}>
                                        <div className={"list-items-img"}><img src={ENV.API.IMG + item.image}/></div>
                                        <div className={"list-items-title"}>{item.title}</div>
                                        <div
                                            className={"list-items-title"}>{moment(item.create_at).format('jYYYY/jM/jD')}</div>
                                        <div className={"list-items-option"}>
                                            <img onClick={() => {
                                                setContinueModal(true);
                                                setPostId(item._id)
                                            }} src={deleteIcon}/>
                                            <img onClick={() => history.push(`/home/edit/post/${item._id}`, {item})}
                                                 src={editIcon}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            case 10:
                return (
                    <>
                        <div onClick={(e) => {
                            e.target === e.currentTarget && setContinueModal(false)
                        }} className={`modal-continue ${continueModal ? "show" : "hidden"}`}>
                            <div className={`modal-continue-box`}>
                                <strong>آیا از ادامه درخواست مطمین هستید ؟</strong>
                                <div>
                                    <div onClick={() => {
                                        deleteData("gallery", postId)
                                    }} className={"btn success"}>
                                        بله
                                    </div>
                                    <div onClick={(e) => {
                                        setContinueModal(false)
                                    }} className={"btn danger"}>
                                        خیر
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => {
                            setShowCategoryModal(!showCategoryModal)
                        }} className={"category-btn"}>
                            انتخاب دسته بندی
                        </div>
                        <ImageUploader onchange={(e) => {
                            setSelectedImage(e.target.files[0])
                        }} id={"input-file-gallery-video"}/>
                        <Input onChange={(e) => {
                            setTitle(e)
                        }} title={"عنوان "}/>
                        <SunEditor
                            setOptions={{
                                height: 200,
                                buttonList: buttonList.complex
                            }}
                            height="400px"
                            getSunEditorInstance={getSunEditorInstance}
                            onChange={(e) => {
                                setDescription(e)
                            }}/>
                        <Bottom onClick={() => {
                            sendGalleryData("video")
                        }} title={"ثبت"}/>
                        <div className={"list"}>
                            {videoList.list && videoList.list.map((item) => {

                                return (
                                    item.type === "video" &&
                                    <div className={"list-items"}>
                                        <div className={"list-items-img"}>
                                            <video loop muted playsInline preload="auto" controls>
                                                <source src={ENV.API.IMG + item.file} />
                                            </video>
                                        </div>
                                        <strong className={"list-items-title"}>{item.title}</strong>
                                        <div className={"list-items-option"}>
                                            <img onClick={() => {
                                                setContinueModal(true);
                                                setPostId(item._id)
                                            }} src={deleteIcon}/>
                                            <img onClick={() => history.push(`/home/edit/gallery/${item._id}`, {item})}
                                                 src={editIcon}/>

                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
        }
    }

    return (
        <Layout>
            <div className={"home"}>
                <div className={"sidebar"}>
                    {
                        tab.map((item) => {
                                return (
                                    <div onClick={() => {
                                        localStorage.setItem("tab", item.id)
                                        setTabs(item.id)
                                    }} className={`sidebar-tab ${tabs === item.id && "selected-tab"}`}>
                                        {item.title}
                                    </div>
                                )
                            }
                        )
                    }
                    <div onClick={() => {
                        handleLogOut()
                    }} className={"sidebar-tab"}>
                        خروج
                    </div>
                </div>
                <div className={"main"}>
                    {
                        selectTab()
                    }
                    {
                        handleCategory()
                    }
                    {
                        ((paginationData?.list?.length > 0)) &&
                        <div className={"pagination"}>
                            <Pagination
                                activePage={+page || 1}
                                itemsCountPerPage={paginationData.per_page}
                                totalItemsCount={paginationData.total}
                                onChange={handlePageChange}
                            />
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default Home;
