
export const ENV = {
    "API": {
        // main
        "admin.onlinenederlandseschool.nl" : "https://onlinenederlandseschool.nl/backend/api",
        "23.227.196.69" : "23.227.196.69/api",
        // test
        "localhost:3000": "https://onlinenederlandseschool.nl/backend/api",
        "localhost:3001": "http://localhost:3000/api",
        "localhost:3002": "http://localhost:3000/api",
        "localhost:3003": "https://onlinenederlandseschool.nl/backend/api",
        "localhost:3004": "https://onlinenederlandseschool.nl/backend/api",
        "localhost:3005": "https://onlinenederlandseschool.nl/backend/api",
        'IMG' : "https://onlinenederlandseschool.nl/backend/storage/"
    }
}
