import { LOGOUT , LOGIN } from "../types";

export const logOut = (payload) => {
    return{
        payload:payload,
        type:LOGOUT
    }
}
export const logIn = (payload) => {
    return{
        payload:payload,
        type:LOGIN
    }
}
