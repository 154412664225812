import {Switch, BrowserRouter as Router, Route} from "react-router-dom";
import Home from './screen/home/home'
import Login from './screen/login/login'
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {StylesProvider, jssPreset} from '@material-ui/core/styles';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Provider} from 'react-redux'
import {store, persistor} from './redux/store'
import {PersistGate} from 'redux-persist/integration/react'
import Edit from "./screen/edit/edit";
import {useEffect} from "react";

const theme = createTheme({
    direction: 'rtl',
    typography: {
        fontFamily: 'vazir',
        fontSize: 11
    },
});

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

function App() {
    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <StylesProvider jss={jss}>
                        <MuiThemeProvider theme={theme}>
                            <Router>
                                <Switch>
                                    <Route  exact="true" path={"/home"} component={Home}/>
                                    <Route path={"/home/edit/:mode/:id"} component={Edit}/>
                                    <Route   path={"/"} component={Login}/>
                                </Switch>
                                <ToastContainer
                                    position="bottom-center"
                                    autoClose={5000}
                                    hideProgressBar={true}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl
                                    pauseOnVisibilityChange
                                    draggable={false}
                                    pauseOnHover/>
                            </Router>
                        </MuiThemeProvider>
                    </StylesProvider>
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
