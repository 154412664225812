import React, {useEffect, useRef, useState} from 'react';
import "../edit/edit.css"
import {useHistory , useParams} from "react-router-dom";
import SunEditor,{buttonList} from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import ImageUploader from "../../component/imageUploader/imageUploader";
import Input from "../../component/input/input";
import Bottom from "../../component/bottom/Bottom";
import {ENV} from "../../services/env";
import moment from "moment-jalaali";
import deleteIcon from "../../res/img/delete.svg";
import editIcon from "../../res/img/edit.png";
import Api from "../../services/api";
import {toast} from "react-toastify";
import {Container, Grid} from "@material-ui/core";
import Layout from "../../component/layout/layout";
import RenderBlogTree from "../../component/renderTree/renderTree";
const Edit = () => {

    const param = useParams()
    const [title, setTitle] = useState("")
    const [selectedImage, setSelectedImage] = useState("")
    const [description, setDescription] = useState("")
    const [type, setType] = useState("")
    const [loading, setloading] = useState(false)
    const [tabs, setTabs] = useState(parseInt(localStorage.getItem("tab")) || 1)
    const [menuData, setMenuData] = useState([])
    const [menuGalleryData, setMenuGalleryData] = useState([])
    const [experienceList, setExperienceList] = useState([])
    const [newCategory, setNewCategory] = useState("")
    const [selectCategory, setSelectCategory] = useState(null)
    const [expanded, setExpanded] = useState([])
    const [menuExperienceData, setMenuExperienceData] = useState([])
    const [showCategoryModal, setShowCategoryModal] = useState(false)
    const [selectedCtegoryId, setSelectedCtegoryId] = useState(null)

    const editor = useRef();

    useEffect(() => {
        getData()
        menu()
        menuExperience()
        menuGallery()
    } ,[])

    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    const getData = () => {
        setloading(true)
        new Api().get(`/${param.mode}/${param.id}`)
            .then((res) => {
                setType(res.type)
                toast.success(res.msg)
                setloading(false)
                setTitle(res.title)
                setSelectedImage(res.image || res.file)
                setDescription(res.description)
                setSelectedCtegoryId(res.category_id)
            })
            .catch((err) => {
                setloading(false)
                console.log(err)
            })

    }

    const menu = () => {
        new Api().get("/menu")
            .then((res) => {
                setMenuData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const menuGallery = () => {
        new Api().get("/menu/gallery")
            .then((res) => {
                setMenuGalleryData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const menuExperience = () => {
        new Api().get("/menu/experience")
            .then((res) => {
                setMenuExperienceData(res.result)
            })
            .catch((err) => {
                toast.error(err)
            })
    }
    const handleAddExpand = async (value, child) => {
        if (expanded.includes(value)) {
            const newArr = [...expanded]
            const index = expanded.indexOf(value);
            await newArr.splice(index, 1);
            await setExpanded(newArr)
        } else {
            const newArr = [...expanded]
            await newArr.push(value);
            await setExpanded(newArr)
        }
    }
    const renderBlogTree = (props, keys = "empty") => {
        let filterKey = props && props?.filter((item) => {
            return (item.type || "empty" ) === keys
        })
        // nodes mapping
        const treeNodes = filterKey && filterKey.map((item, key) => {
            // value id node
            const {_id} = item;
            // label title node
            const {title} = item;
            // slug
            const {slug} = item;

            // check has child
            const hasChild =item && item.child && item.child.length > 0;

            // check has child is true fetch children

            const children = hasChild ? renderBlogTree(item.child ,keys) : '';
            return (
                <>
                    <div
                        className={"tree_view"}>
                        {hasChild === true &&
                        <img onClick={(e) => e.target === e.currentTarget && handleAddExpand(_id, item.child)}
                             src={!expanded.includes(_id) ? "/plus_icon.svg" : "/minus_icon.svg"}/>
                        }
                        <li key={key} className={"tree_box"}>
                          <span
                              className={
                                  hasChild === true
                                      ? 'tree-parent has-child'
                                      : 'tree-parent  has-no-child'
                              }
                          >
                          </span>
                            <div  className={"tree_box_title"}>
                                <input checked={( selectedCtegoryId ) === item._id} className={"radio-category"} name="category" type={"radio"} value={item._id} onChange={(e) => {setSelectCategory(e.target.value) ; setSelectedCtegoryId(e.target.value)}}/>
                                <div> { title }</div>
                            </div>
                            {hasChild === true && (
                                <ul
                                    style={{
                                        display: expanded.includes(_id)
                                            ? 'flex'
                                            : 'none'
                                    }}
                                    className={"tree_children"}
                                >
                                    <div>
                                        {children}
                                    </div>

                                </ul>
                            )}
                        </li>
                    </div>
                </>
            );
        });
        return treeNodes;
    }
    const handleTree = (tabs) => {
        switch (tabs) {
            case 1 :
                return renderBlogTree(menuData ,"menu")
            case 3 :
                return renderBlogTree(menuGalleryData ,"image")
            case 10 :
                return renderBlogTree(menuGalleryData ,"video")
            case 14 :
                return renderBlogTree(menuExperienceData )
        }
    }
    const handleCategory = () => {
        return(
            <div className={`${showCategoryModal ? "show-category-modal" : "hide-category-modal" } modal-category` }>
                {handleTree(tabs)}
                <div  className={"category-btn-section"}>
                    <div  onClick={() => {setShowCategoryModal(!showCategoryModal)}}  className={"category-btn blue"}>
                        انتخاب
                    </div>
                    <div  onClick={() => {setShowCategoryModal(!showCategoryModal) ; setSelectedCtegoryId(-1)}}  className={"category-btn red "}>
                        حذف
                    </div>
                </div>
            </div>
        )
    }
    const sendBlogData = (e) => {

        const formData = new FormData()
        const images = selectedImage
        formData.append("title", title)
        formData.append("description", description)
        formData.append("image", images)
        formData.append("category_id", selectedCtegoryId)
        new Api().put(`/${param.mode}/${param.id}`, formData)
            .then((res) => {
                toast.success(res.msg)
                getData()
            })
            .catch((res) => {
                toast.error("خطایی رخ داده است")
            })
    }
    return (
        <Layout>
            <div className={"edit"}>
                <Container>
                        <div className={"edit-section"}>
                            {
                                param.mode !== "post" &&
                                    <div onClick={() => {setShowCategoryModal(!showCategoryModal)}} className={"category-btn"}>
                                        انتخاب دسته بندی
                                    </div>
                            }

                            <ImageUploader type={type} src={selectedImage} onchange={(e) => {
                                setSelectedImage(e.target.files[0])
                            }} id={"input-file-blog"}/>
                            {
                                title && <Input value={title} onChange={(e) => {
                                    setTitle(e)
                                }} title={"عنوان "}/>
                            }
                            {
                                description &&
                                <SunEditor
                                    defaultValue={description}
                                    setOptions={{
                                        height: 200,
                                        buttonList: buttonList.complex
                                    }}
                                    height="400px"
                                    getSunEditorInstance={getSunEditorInstance}
                                    onChange={(e) => {
                                        setDescription(e)
                                    }}/>
                            }
                            <Bottom onClick={() => {
                                sendBlogData()
                            }} title={"ثبت"}/>
                        </div>
                </Container>
                {
                    handleCategory()
                }
            </div>
        </Layout>
    );
};

export default Edit;