import React from 'react';
import "./header.css"
const Header = () => {
    return (
        <div className={"header"}>
             <h1>پنل ادمین آموزشگاه زبان هلندی </h1>
        </div>
    );
};

export default Header;
